import styled from "styled-components"

export const PageTitle = styled.h1`
  font-size: 28px;
  font-weight: bold;
  font-weight: normal;
  margin-bottom: ${props => props.theme.gap * 4}px;
  margin-top: ${props => props.theme.gap * 4}px;
  text-align: center;
  text-transform: uppercase;
`
